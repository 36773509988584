/* global document */
import naja from 'naja';
import netteForms from 'nette-forms';
import 'bootstrap';

import BootstrapModalExtension from './BootstrapModalExtension';
import './forms';
import './menu';
import './multiselect';
import './rating';
import './tooltips';
import './wysiwyg';

naja.formsHandler.netteForms = netteForms;
naja.registerExtension(new BootstrapModalExtension());
document.addEventListener('DOMContentLoaded', () => naja.initialize());
