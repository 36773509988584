/* global document */
import { Collapse } from 'bootstrap';

const handleChange = (el, load = false) => {
	const wrap = el.closest('.js-multiselect');
	const valueInputs = wrap.querySelectorAll('.js-multiselect-input');
	const allChecks = wrap.querySelectorAll('.js-multiselect-check');
	const preview = wrap.querySelector('.js-multiselect-preview');
	const { value } = el;
	const { name } = el.dataset;

	if (wrap.classList.contains('js-multiselect-single')) {
		allChecks.forEach((c) => {
			const check = c;
			if (check !== el && check.checked) {
				check.checked = false;
			}
		});
		valueInputs.forEach((i) => {
			const input = i;
			if (el.dataset.type && input.name === el.dataset.type && el.checked) {
				input.value = value;
			} else {
				input.value = '';
			}
		});
		if (name && el.checked) {
			preview.value = name;
		} else {
			preview.value = '';
		}
	} else {
		valueInputs.forEach((i) => {
			const input = i;
			if (el.dataset.type && input.name === el.dataset.type && !load) {
				const values = input.value ? input.value.split(',') : [];
				input.value = (values.includes(value)
					? values.filter((c) => c !== value)
					: [...values, value]).join();
			}
		});

		const values = preview.value ? preview.value.split(', ') : [];
		if (name) {
			preview.value = (values.includes(name)
				? values.filter((c) => c !== name)
				: [...values, name]).join(', ');
		}
	}
};

const uncollapseParents = (el) => {
	const collapse = el.closest('.js-multiselect-collapse');
	if (collapse) {
		const collapseObject = new Collapse(collapse);
		collapseObject.show();

		const collapseParent = collapse.parentElement.closest('.js-multiselect-collapse');

		if (collapseParent) {
			const collapseParentObject = new Collapse(collapseParent);
			collapseParentObject.show();
		}
	}
};

document.addEventListener('change', (event) => {
	if (!event.target.classList.contains('js-multiselect-check')) {
		return;
	}

	handleChange(event.target);
});

document.querySelectorAll('.js-multiselect').forEach((wrap) => {
	const valueInputs = wrap.querySelectorAll('.js-multiselect-input');

	valueInputs.forEach((i) => {
		const input = i;
		const values = input.value ? input.value.split(',') : [];
		values.forEach((n) => {
			const check = document.getElementById(`${input.name}${n}`);
			check.checked = true;
			handleChange(check, true);
			uncollapseParents(check);
		});
	});
});
