/* global document */
import { Modal } from 'bootstrap';

export default class BootstrapModalExtension {
	initialize(naja) {
		this.naja = naja;

		const modals = document.querySelectorAll('.modal');
		Array.prototype.forEach.call(modals, (modal) => {
			if (!modal.id || !modal.id.startsWith('snippet-')) {
				return;
			}

			BootstrapModalExtension.showModal(modal);
		});

		naja.snippetHandler.addEventListener('afterUpdate', (event) => {
			BootstrapModalExtension.showModal(document.getElementById(event.detail.snippet.id));
		});

		document.addEventListener('shown.bs.modal', (event) => {
			if (!event.target.classList.contains('modal')) {
				return;
			}

			const modalInputs = event.target.querySelectorAll('input:not([type="hidden"]):not([type="radio"]):not([type="checkbox"]), textarea, select');
			if (modalInputs.length > 0) {
				modalInputs[0].focus();
			}
		});

		document.addEventListener('hidden.bs.modal', (event) => {
			if (!event.target.classList.contains('modal')) {
				return;
			}

			const dialog = event.target.querySelector('.modal-dialog');
			const url = !!dialog && dialog.dataset.closeUrl;
			if (url && !!event.target.querySelector('.modal-content')) {
				naja.makeRequest('GET', url, null, {
					abort: false,
					unique: false,
				});
			}
		});
	}

	static showModal(modal) {
		if (!modal || !modal.classList.contains('modal')) {
			return;
		}

		const display = !!modal.querySelector('.modal-content');
		let modalObject = Modal.getInstance(modal);
		if (modalObject) {
			modalObject._isTransitioning = false; // eslint-disable-line no-underscore-dangle
		} else {
			modalObject = new Modal(modal);
		}
		if (display) {
			modalObject.show();
		} else {
			modalObject.hide();
		}
	}
}
