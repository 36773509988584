/* global document */
import netteForms from 'nette-forms';

netteForms.showFormErrors = function showFormErrors(form, errors) {
	const displayed = [];
	let focusElem;

	form.querySelectorAll('.is-invalid').forEach((el) => {
		el.classList.remove('is-invalid');
	});
	form.querySelectorAll('.js-form-error').forEach((el) => {
		el.remove();
	});

	for (let i = 0; i < errors.length; i += 1) {
		const { message } = errors[i];
		const $target = errors[i].element;

		if (!displayed.some((error) => error.target === $target && error.message === message)) {
			$target.classList.add('is-invalid');
			const feedbackEl = document.createElement('span');
			feedbackEl.classList.add('invalid-feedback', 'js-form-error');
			feedbackEl.innerText = message;
			$target.parentNode.insertBefore(feedbackEl, $target.nextSibling);
			displayed.push({ $target, message });
		}

		$target.classList.add('is-invalid');

		if (!focusElem && $target.focus) {
			focusElem = $target;
		}
	}

	if (focusElem) {
		focusElem.focus();
	}
};

netteForms.initOnLoad();

document.addEventListener('click', (event) => {
	const { target } = event;
	if (!target.classList.contains('js-password-toggle')) {
		return;
	}

	target.classList.toggle('is-visible');
	target.parentNode.querySelector('.js-password').type = target.classList.contains('is-visible') ? 'text' : 'password';
});

document.addEventListener('change', (event) => {
	const { target } = event;
	if (!target.classList.contains('js-file-upload')) {
		return;
	}

	if (target.files) {
		const parent = target.parentNode.parentNode.parentNode;
		const image = parent.querySelector('.js-file-upload-image');

		image.src = URL.createObjectURL(target.files[0]);
		if (image.parentNode.classList.contains('d-none')) {
			image.parentNode.classList.remove('d-none');
		} else {
			image.classList.remove('d-none');
		}
		parent.querySelector('.js-file-upload-reset').classList.remove('d-none');
	}
});

document.addEventListener('click', (event) => {
	const { target } = event;
	if (!target.classList.contains('js-file-upload-reset')) {
		return;
	}

	const deleteLogoCheckbox = document.querySelector('.js-file-upload-delete');
	if (deleteLogoCheckbox) {
		deleteLogoCheckbox.checked = true;
	}

	const parent = target.parentNode.parentNode.parentNode;
	const image = parent.querySelector('.js-file-upload-image');
	const upload = parent.querySelector('.js-file-upload');

	image.src = '';
	upload.value = '';
	image.classList.add('d-none');
	target.classList.add('d-none');
});

document.addEventListener('click', (event) => {
	const { target } = event;
	if (!target.classList.contains('js-form-clear')) {
		return;
	}
	target.parentNode.querySelector(':scope > input').value = '';
	const valueInputs = target.parentNode.querySelectorAll('.js-multiselect-input');
	valueInputs.forEach((i) => {
		const input = i;
		input.value = '';
	});
	target.closest('form').submit();
});
