/* global document */
import naja from 'naja';

let previousCheckedRatingInput = null;

function handleClick(event, ratingInputs, ratingResult) {
	const { currentTarget } = event;
	const inputs = ratingInputs;
	const result = ratingResult;

	if (currentTarget.checked && previousCheckedRatingInput?.value !== currentTarget.value) {
		previousCheckedRatingInput = currentTarget;
	} else if (previousCheckedRatingInput?.value === currentTarget.value) {
		previousCheckedRatingInput = ratingInputs[1]; // eslint-disable-line prefer-destructuring
	} else {
		currentTarget.checked = true;
		previousCheckedRatingInput = currentTarget;
	}

	for (let i = 0; i < inputs.length; i += 1) {
		if (+inputs[i].value <= +currentTarget.value) {
			inputs[i].checked = currentTarget.checked;
		} else {
			inputs[i].checked = false;
			inputs[i].classList.remove('is-hover');
		}
	}

	if (result) {
		if (currentTarget.checked) {
			result.innerHTML = +currentTarget.value;
		} else {
			result.innerHTML = +previousCheckedRatingInput.value;
		}
	}
}

function handleHover(isMouseEntering, ratingInput, ratingInputs, ratingResult) {
	const inputs = ratingInputs;
	const result = ratingResult;

	for (let i = 0; i < inputs.length; i += 1) {
		if (
			(!isMouseEntering && +inputs[i].value > +previousCheckedRatingInput?.value)
			|| (isMouseEntering && +inputs[i].value > +ratingInput?.value)
		) {
			inputs[i].checked = false;
			inputs[i].classList.remove('is-hover');
		} else if (
			(!isMouseEntering && +inputs[i].value <= +previousCheckedRatingInput?.value)
			|| +inputs[i].value <= +ratingInput?.value
		) {
			inputs[i].classList.add('is-hover');
		}
	}

	if (ratingResult) {
		if (isMouseEntering) {
			result.innerHTML = +ratingInput.value;
		} else if (previousCheckedRatingInput) {
			previousCheckedRatingInput.checked = true;
			result.innerHTML = +previousCheckedRatingInput.value;
		}
	}
}

function init(container) {
	const ratings = container.querySelectorAll('.js-rating');

	ratings.forEach((rating) => {
		const ratingInputs = rating.querySelectorAll('.js-rating-input');
		const ratingLabels = rating.querySelectorAll('.js-rating-label');
		const ratingResult = rating.querySelector('.js-rating-result');

		previousCheckedRatingInput = ratingInputs[ratingInputs.length - 2];

		ratingLabels.forEach((ratingLabel) => {
			const ratingInput = rating.querySelector(`.js-rating-input#${ratingLabel.getAttribute('for')}`);
			ratingLabel.addEventListener('mouseenter', () => handleHover(true, ratingInput, ratingInputs, ratingResult));
			ratingLabel.addEventListener('mouseleave', () => handleHover(false, ratingInput, ratingInputs, ratingResult));
		});

		ratingInputs.forEach((ratingInput) => {
			ratingInput.addEventListener('change', (event) => handleClick(event, ratingInputs, ratingResult));
		});
	});
}

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
	init(event.detail.snippet);
});

init(document);
